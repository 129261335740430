.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000aa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: opacity 250ms linear, transform 250ms cubic-bezier(0.5,  0.0, 0.08, 1.0), background 200ms linear;
  overflow-x: hidden;
  padding: 100px 0;
  box-sizing: border-box;

  @include mobile {
    overflow: hidden;
    background-color: #000000;
    height: 100%;
    padding: 0;

    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%; // or right: 0;
    // height: 100%; // or bottom: 0;
  }

  &--wide {
    .popup__wrapper {
      width: 1280px;
      //overflow-y: hidden;
    }

    // .popup__inner_wrapper {
    //   max-height: 100%;
    //   overflow-y: scroll;
    // }

    .popup__content {
      display: flex;
      flex-direction: column;
    }
  }

  &--fixedButtons {
    .popup__buttons {
      //position: sticky;
      left: 0;
      bottom: -40px;
      padding: 20px 0;
      background-color: #fff;
    }
  }

  &--enter {
    opacity: 1;
    background-color: #00000000;

    .popup__wrapper { 
      overflow: hidden;
      opacity: 0;
      @include mobile {
        opacity: 1;
        transform: translateY(100%);
      }
    }

    @include mobile {
      opacity: 1;
      overflow: hidden;
    }
  }

  &--enter-active {
    opacity: 1;
    background-color: #000000aa;

    .popup__wrapper { 
      overflow: hidden;
      opacity: 1;
      transition: opacity 250ms linear;
      @include mobile {
        opacity: 1;
        transition: transform 250ms cubic-bezier(0.5,  0.0, 0.08, 1.0);
        transform: translateY(0);
      }
    }

    @include mobile {
      opacity: 1;
      overflow: hidden;
    }
  }

  &--exit {
    opacity: 1;
    background-color: #000000aa;

    .popup__wrapper { 
      overflow: hidden;
      @include mobile {
        transform: translateY(0);
      }
    }

    @include mobile {
      opacity: 1;
      overflow: hidden;
    }
  }

  &--exit-active {
    opacity: 1;
    background-color: #00000000;
    overflow: hidden;

    .popup__wrapper { 
      opacity: 0;
      transition: opacity 250ms linear;
      @include mobile {
        opacity: 1;
        transition: transform 250ms cubic-bezier(0.5,  0.0, 0.08, 1.0);
        transform: translateY(100%);
      }
    }

    @include mobile {
      opacity: 1;
      overflow: hidden;
    }
  }

  &--hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  &__wrapper { 
    background-color: #fff;
    padding: 40px;
    width: 380px;
    //flex: 1;
    //max-height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    //transition: height 250ms cubic-bezier(0.5,  0.0, 0.08, 1.0), transform 250ms cubic-bezier(0.5,  0.0, 0.08, 1.0);

    transition: height 250ms cubic-bezier(0.5,  0.0, 0.08, 1.0);


    &--pushing {
      transition: transform 350ms cubic-bezier(0.5,  0.0, 0.08, 1.0), opacity 350ms linear;
    }

    &--pushed {
      opacity: 0.6;
      transform: translateX(-200px);
      transition: transform 350ms cubic-bezier(0.5,  0.0, 0.08, 1.0), opacity 350ms linear;
    }

    &--to_push {
      transform: translateX(100%);
      z-index: 100;
    }

    &--poped {
      transform: translateX(100%);
      transition: transform 350ms cubic-bezier(0.5,  0.0, 0.08, 1.0), opacity 350ms linear;
      z-index: 100;
    }

    &--to_pop {
      opacity: 0.6;
      transform: translateX(-200px);

    }

    &--shadow {
      position: absolute;
      z-index: -1;
      visibility: hidden;

      @include mobile {
        z-index: 2;
        visibility: visible;
      }
    }

    &--alert {
      .popup__close {
        display: none;
      }

      .popup__title {
        margin-top: 0;
      }
    }

    @include mobile {
      max-width: initial;
      width: 100%;
      min-height: 100%;
      padding: 30px;
      justify-content: center;
      overflow-y: auto;
    }
  }

  &__inner_wrapper {
    transition: opacity 200ms linear;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    @include mobile {
      flex: 1;
      justify-content: center;
      min-height: 100%;
      position: relative;
    }
  }

  &__title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: "#2E2138";
    text-align: center;

    &--image {
      margin-top: 20px;
    }

    @include mobile {
      margin-top: 0;
    }
  }

  &__image {
    text-align: center;
    background-color: #eeeeee;
    border-radius: 50%;

    @include mobile {
      margin-bottom: 30px;
    }

    img {
      vertical-align: bottom
    };
  }

  &__subtitle {
    margin-bottom: 10px;
  }

  &__content {
    font-weight: 200;
    line-height: 23px;
    flex: 1;
    overflow: hidden;

    @include mobile {
      display: flex;
      //flex: 1;
      flex-direction: column;
    }
  }

  &__scroller {
    overflow-y: auto;
  }

  .form {
    @include mobile {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    &--full_width {
      .btn {
        margin: 0 !important;
        flex: 1;
      }
    }
    
    @include mobile {
      // margin-left: -5px;
      // margin-right: -5px;
      // margin-top: auto;
      // padding-top: 28px;
      position: absolute;
      bottom: 0;
      left: -5px;
      right: -5px;
    }

    .btn {
      margin-left: 5px;
      margin-right: 5px;
      
      @include mobile {
        flex: 1;
      }
    }
  }

  &__close {
    width: 40px;
    height: 40px;
    background-image: url(../assets/images/icn_close.svg);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 27px;
    right: 27px;
    cursor: pointer;

    @include mobile {
      top: 17px;
      right: 18px;
      z-index: 10;
    }
  }

  &__back {
    width: 40px;
    height: 40px;
    background-image: url(../assets/images/icn_back_black.svg);
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 27px;
    left: 27px;
    cursor: pointer;

    @include mobile {
      top: 17px;
      left: 18px;
      z-index: 10;
    }
  }
}

.react-slidedown {
  height: 0;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}

.popup__image {
  width: 74px;
  height: 74px;
  align-self: center;
}