/* Eric Meyer's CSS Reset
   http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   This is a Sass partial
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button,
input[type="button"],
input[type="submit"] {
  outline: none;
  background: transparent;
  line-height: 1; }

* {
  font-family: 'Noto Sans', sans-serif; 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@import "./mixins.scss";
@import "./header.scss";
@import "./popup.scss";
@import "./dashboard.scss";
@import "./forms.scss";
@import "./pipeline.scss";

html,
body {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #171717;
  position: fixed;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.btn {
  display: inline-block;
  border-radius: 2px !important;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px 13px;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;

  &--purple {
    background-color: #5A4966;
    color: #ffffff;

    &:hover {
      background-color: #483953;
    }
  }

  &--red {
    background-color: #E84622;
    color: #ffffff;

    &:hover {
      background-color: #D23E1D;
    }
  }

  &--white {
    border: 1px solid rgba(0,0,0,0.4);
    color: rgba(0,0,0,0.67);

    &:hover {
      border: 1px solid rgba(0,0,0,0.57);
      color: rgba(0,0,0,0.87);
    }
  }

  &--social {
    padding: 12px 11px 13px;
    border: 1px solid rgba(0,0,0,0.2)  !important;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    display: flex;
    flex: 1;
    img {
      margin-right: 21px;
      margin-top: 1px;
    }
  }
}

.ddl {
  position: relative;
  cursor: pointer;

  &:hover {
    .ddl__options {
      visibility: visible;
    }
  }

  &__options {

    .wrapper {
      
      background-color: #ffffff;
      border: 1px solid #F7F7F7;
      padding: 30px 40px;
      border-radius: 2px;
      box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.2);
    }
    visibility: hidden;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    position: absolute;
    left: 0;
    top: 100%;
    
    z-index: 10000;
    
    
  }
}

.progress {
  vertical-align: bottom;
  animation: spin 1000ms infinite linear;
}

button {
  &.btn {
    border: none;
    appearance: none;
  }
}

.propoganda {
  height: 450px;
  background-color: #30213C;
}

.flex_spacer {
  flex: 1
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(359deg);
  }
}