.dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 30px;
    box-sizing: border-box;
    background-color: #5A4966;
    z-index: 2000;

    .org_ddl {
      display: flex;
      align-items: center;

      .ddl__options {
        width: 300px;
      }
    }

    .org_logo {
      display: block;
      object-fit: cover;
      width: 40px;
      height: 40px;
      background-color: #EBE9ED;
      border: none;
      border-radius: 4px;
      appearance: none;
      margin-right: 20px;
    }

    .org_name {
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      margin-right: 10px;
    }

    .menu {
      margin-left: auto;
  
      .btn {
        margin-left: 20px;
      }
      
      &__link {
        font-size: 14px;
        color: #ffffff;
        font-weight: 500;
        text-decoration: none;
        display: inline-block;
        position: relative;
        margin: 0 20px;
        cursor: pointer;
  
        &:last-of-type {
          margin-right: 0;
        }
  
        &--active, &:hover {
          
          text-decoration: none;
  
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  &__form {
    align-self: center;
    width: 100%;
    max-width: 920px;
    flex: 1;

    &__section {
      margin: 30px 0;
      padding: 20px;

      .table {
        margin-top: -30px;
      }

      .table__head {
        margin-left: -20px;
        margin-right: -20px;
      }
    }

    &__header {
      margin-left: -20px;
      font-size: 16px;
      color: #2E2138;
      letter-spacing: 0;
      border-bottom: 1px solid #F7F7F7;
      padding-bottom: 15px;
      width: calc(100% + 40px);
    }
  }

  &__main_menu {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 50px;
    box-sizing: border-box;
    background-color: #877594;
    //z-index: 2000;

    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;

    .account {
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      padding: 0 20px;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &.disabled {
        opacity: 0.5;
        cursor: auto;
      }

      &.right {
        margin-left: auto;
      }

      &.active {
        &:after {
          content: "";
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #F2AB4A;
        }
      }
    }
  }

  &__cta {
    align-self: center;
    justify-self: center;
    margin: auto 0;
    font-size: 20px;
    color: #2E2138;
    letter-spacing: 0;
    text-align: center;
    max-width: 250px;
    line-height: 27px;

    a {
      color: #E88022;
    }

    .progress {
      width: 36px;
    }
  }
}

.organisation_details {
  >.form__row {
    margin: 20px 0;
  }

  &__avatar {
    display: block;
    width: 128px;
    height: 128px;
    background-size: cover;
    background-color: #D8D8D8;
    cursor: pointer;
    margin-right: 30px;
    border-radius: 3px
  }

  &__buttons {
    margin-top: 24px;
    justify-content: flex-end;

    .btn {
      margin-left: 20px;
    }
  }

  &__account_row {
    display: flex;
    align-items: center;

    .form__field {
      flex: 1;
      margin-right: 20px;
    }
  }

  .form__field__accessory--copy {
    cursor: pointer;
    margin-right: 15px;
    margin-top: -11px;

    

    img {
      opacity: 0.5;
      vertical-align: middle;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }

    .copied {
      color: #F2AB4A;
      margin-right: 10px;
      transition: opacity 300ms;
    }
  }
}

.tagNames {
  padding: 8px 0;

  >div {
    padding: 3px 0;

    span {
      font-weight: 600;
    }
  }
}