.form {
  &--full {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
  }

  &--no_top_padding {
    padding-top: 0 !important;
  }

  &__row {
    display: flex;
  }

  input:not([type="checkbox"]),
  textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #F7F7F7;
    padding: 28px 0 8px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 23px;
    outline: none;
    -webkit-text-fill-color: rgba(
      0,
      0,
      0,
      0.87
    ); /* Override iOS / Android font color change */
    -webkit-opacity: 1;
    border-radius: 0;

    &.no-border {
      border: none;
    }

    &:read-only {
      color: rgba(0, 0, 0, 0.2) !important;
      -webkit-text-fill-color: rgba(
        0,
        0,
        0,
        0.2
      ) !important;
      border-color: #F7F7F7 !important;
    }

    &.no-label-on-focus {
      &:focus {
        + span {
          opacity: 0 !important;
        }
      }

      &:not(:placeholder-shown) {
        + span {
          opacity: 0 !important;
        }
      }
    }

    &.input-validation-error {
      border-bottom: 1px solid #e2442a;

      &::placeholder {
        color: #e2442a !important;
      }

      + span {
        color: #e2442a;
      }
    }

    &:focus {
      border-color: #5a4966;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #969696;
      -webkit-text-fill-color: #969696; /* Override iOS / Android font color change */
      -webkit-opacity: 1;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #969696;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #969696;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #969696;
    }

    &[type="submit"] {
      background-color: #c8b17f;
      border-color: #c8b17f !important;
      color: white;
      cursor: pointer;
      -webkit-text-fill-color: #fff;
    }
  }

  &__inline_buttons {
    .btn {
      margin-left: 10px;
    };
  }

  &__field {
    position: relative;

    &--vertical {
      display: flex;

      > div {
        flex: 1;
        margin: 0 5px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &__accessory {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -8px;
      cursor: pointer;
    }

    &--text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      line-height: 23px;
      margin-top: 20px;

      &--subtitle {
        margin-top: 0;
        margin-bottom: 20px;
      }

      a {
        cursor: pointer;
        color: #e88022;
      }
    }
  }

  &__forgot {
    margin-left: auto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 23px;
    font-style: italic;
    padding: 12px 0;
    cursor: pointer;
  }
}

.or {
  flex: 1;
  height: 1px;

  position: relative;

  background-color: #F7F7F7;

  margin: 16px 0 !important;

  &:after {
    /* or: */

    font-size: 13px;
    color: rgba(0,0,0,0.5);
    line-height: 13px;

    display: block;
    content: "or";

    position: absolute;

    left: 50%;
    margin-left: -30px;

    top: -5px;

    background-color: white;
    width: 60px;

    text-align: center;
  }
}

.form_errors {
  color: #e2442a;
  font-size: 0.923em;

  &__error {
    margin-top: 3px;
  }
}

.form_success {
  color: #76d4a1;
  font-size: 0.923em;

  &__message {
    margin-top: 3px;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;

  button {
    height: 100%;
  }

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
  }
}

.orgRow {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 0 30px;

  &:hover {
    background-color: #FBFBFB;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .org_logo {
    display: block;
    object-fit: cover;
    width: 60px;
    height: 60px;
    background-color: #EBE9ED;
    border: none;
    border-radius: 4px;
    appearance: none;
    margin-right: 20px;
  }
}

.icn_go {
  margin-left: auto;
  background-image: url('../assets/images/icn_go.svg');
  width: 9px;
  height: 16px;
  background-size: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-box-shadow: 0 0 0 40px white inset !important;
  background-color: #fafafa !important;
  background-clip: content-box !important;
}

@mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {
  label,
  > span {
    position: absolute;
    left: 0;
    top: 10px;
    cursor: text;
    font-size: 1em;
    opacity: 1;
    transition: all 0.2s;

    @content;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 1;
    transition: all 0.2s;
  }

  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
    visibility: visible;
    font-size: 1.077em;
    @content;
  }
}

.has-float-label {
  @include float-label-container;
  @include float-label;

  span {
    color: rgba(0, 0, 0, 0.2);
    line-height: 23px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  textarea {
    width: 100%;
  }

  > input,
  > select,
  > textarea {
    @include float-label-input;
    @include float-label-scaled {
      top: 18px;
    }

    &:focus {
      outline: none;
      border-color: #5a4966;
    }

    &:disabled {
      background-color: white;
    }
  }

  select {
    padding-right: 1em;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.5em bottom 0.25em;
    background-size: 8px 10px;
  }
}

.checkbox {
  padding: 12px 0;
  line-height: 23px;
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  align-items: center;

  &--big {
    input {
      width: 32px !important;
      height: 32px !important;
      border: 1px solid rgb(223, 221, 221) !important;
    }
  }

  input {
    width: 14px;
    height: 14px;
    border: 1px solid #F7F7F7;
    border-radius: 1px;
    appearance: none;
    outline: none;
    position: relative;
    margin: 1px 7px 0 0;

    &:checked {
      border: 5px solid #e88022;
      border-radius: 2px;
      //background-color: #E88022;
    }
  }
}

.checkboxInput {
  width: 14px;
  height: 14px;
  border: 1px solid #F7F7F7;
  border-radius: 1px;
  appearance: none;
  outline: none;
  position: relative;
  margin: 1px 7px 0 0;

  &--big {
    width: 32px !important;
    height: 32px !important;
    border: 1px solid rgb(223, 221, 221) !important;
    border-radius: 2px;

    &:checked {
      position: relative;
      background-color: #ffffff;
      

      &:after {
        display: block;
        position: absolute;
        content: "";
        left: 4px;
        top: 4px;
        right: 4px;
        bottom: 4px;
        background-color: #e88022;
        border-radius: 2px;
      }
    }
  }


  &:checked {
    border: 5px solid #e88022;
    border-radius: 2px;
    //background-color: #E88022;
  }
}

.no-float-label {
  > input,
  > select,
  > textarea {
    padding: 24px 0 20px !important;

    &.input-validation-error {
      &::placeholder {
        color: #e2442a;
        -webkit-text-fill-color: #e2442a;
      }
    }

    &::placeholder {
      font-size: 13px;
    }
  }
}
