header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 2000;

  .logo {
    font-size: 20px;
    color: #30213C;
  }

  .menu {
    margin-left: auto;

    .btn {
      margin-left: 20px;
    }
    
    &__link {
      font-size: 14px;
      color: rgba(0,0,0,0.87);
      font-weight: 500;
      text-decoration: none;
      display: inline-block;
      position: relative;
      margin: 0 20px;
      cursor: pointer;

      &--active, &:hover {
        
        text-decoration: none;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: rgba(0,0,0,0.87);
        }
      }
    }
  }
}