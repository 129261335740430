@mixin tablet-landscape {
  @media (max-width: 1024px) { //and (min-width: #{$tablet-land-width})
      @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: 768px) {
      @content;
  }
}

@mixin only_tablet {
  @media (min-width: 721px) and (max-width: 1024px) {
      @content;
  }
}

@mixin mobile {
  @media (max-width: 720px) {
      @content;
  }
}

@mixin mid-desktop {
  @media (max-width: 1440px) { //and (min-width: #{$tablet-land-width})
      @content;
  }
}

@mixin touch {
  @media (pointer: coarse) {
      @content;
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(359deg);
  }
}