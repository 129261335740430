.pipeline {
  padding: 0 30px 30px;
}

.table {
  &__row {
    display: flex;
    align-items: center;
  }
  
  &__content {
    height: 50px;

    &:nth-of-type(2n + 1){
      background-color: #FBFBFB;
    }

    .t0 {
      padding-left: 30px;
      width: 470px;
    }

    .t1 .pipeline_link {
      padding-left: 30px;
      width: 400px;
      color: #E88022;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  &__head {
    margin-top: 30px;
    border-bottom: 1px solid #F7F7F7;
    line-height: 50px;
    color: rgba(0,0,0,0.5);
    font-size: 14px;

    .t1 {
      font-size: 16px;
      color: #2E2138;
    }
  }

  .t1 {
    width: 430px;
  }
  
  .t2, .t3, .t4 {
    width: 200px;
  }
  
  .t5 {
    flex: 1;
  }
  
  .t6 {
    width: 70px;
    text-align: center;
  }
  
  .t6 img {
    padding-top: 4px;
  }
}

.graph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  position: relative;

  &:before {
    display:block;
    content: "";
    position: absolute;
    height: 3px;
    background-color: #D8D8D8;
    left: 0;
    right: 0;
    z-index: 1;
  }
  
  .dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #877594;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    background-image: url(../assets/images/icn_check.svg);

    &.with-tags {
      &:after {
        display:block;
        content: "";
        position: absolute;
        height: 4px;
        width: 4px;
        background-color: #E88022;
        right: -2px;
        top: -2px;
        border-radius: 2px;
        z-index: 1;
      }
    }
  }
  
  .dot:hover {
    position: relative;
    z-index: 3;
  }
  
  .dot:hover .graph__stage_action {
    display: block;
  }
  
  .dot--big {
    width: 31px;
    height: 31px;
    background-size: 40%;
    margin-left: -3px;
    margin-right: -3px;
  }
  
  .dot--white {
    background-color: #D8D8D8;
    background-image: none;
  }
  
  .dot--yellow {
    background-color: #F2AB4A;
    color: #fff;
    background-image: none;
  }
  
  .dot--blue {
    background-color: #6090C5;
    color: #fff;
    background-image: none;
  }
  
  .dot--green {
    background-color: #69C394;
    color: #fff;
    &.dot--big {
      background-size: 50%;
    }
  }
  
  .dot--red {
    background-color: #E84622;
    color: #fff;
    background-image: url(../assets/images/icn_cross.svg);
  }
}

.graph__stage_action {
  position: absolute;
  background-color: #fff;
  border: 1px solid #F7F7F7;
  top: 40px;
  right: -50px;
  display: none;
  box-shadow: 1px 1px 30px rgba(0,0,0,0.3);
  padding: 10px 20px 10px;
}

.stage_name {
  padding-bottom: 20px;
  padding-top: 10px;
  color: rgba(0,0,0,0.87);
  font-size: 18px;
  align: center;
}

.stage_group {
  color: #000;
  border-bottom: 1px solid #F7F7F7;
  margin-bottom: 10px;
  padding-left: 10px;

  &__name {
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: -10px;
  }
}

.stage_action {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #000;
  font-size: 14px;
}

.stage_action__status {
  margin-right: 20px;
}

.stage_artifact_id {
  color: rgba(0,0,0,0.87);
  font-size: 11px;
  padding-bottom: 10px;
  white-space: nowrap;
  line-height: 15px;

  span {
     font-weight: 600; 
  }
}
